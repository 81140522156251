.authWrapper {
  background-color: var(--chakra-colors-blue-100);

  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  height: 100vh;

  > div {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    height: 100vh;
  }
}