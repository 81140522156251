.loginWrapper {
  display: flex;
  flex-direction: column;
  width: 30vw;
  background: var(--chakra-colors-white);
  border-radius: var(--chakra-radii-md);
  
  .loginHeader {
    border-top-left-radius: var(--chakra-radii-md);
    border-top-right-radius: var(--chakra-radii-md);
    background-color: var(--chakra-colors-gray-50);
    border-bottom: 1px solid var(--chakra-colors-gray-100);
    margin-bottom: var(--chakra-space-4);
  }

  .loginContent {
    flex-grow: 2;
    border-bottom: 1px solid var(--chakra-colors-gray-100);
    padding-bottom: var(--chakra-space-4);

    .socialLogins {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-top: var(--chakra-space-4);
      
      .socialLogin {
        background: var(--chakra-colors-gray-100);
        border-radius: var(--chakra-radii-md);
        margin-right: var(--chakra-space-4);
        cursor: pointer;
        
        :hover {
          transition: all ease-in-out 0.1s;
          border-radius: var(--chakra-radii-md);
          background: var(--chakra-colors-blue-300);
          color: var(--chakra-colors-white);
        }
        
        svg {
          height: 100%;
          width: 100%;
          padding: var(--chakra-space-2);
        }
      }
    }
  }

  .loginFooter {
    background-color: var(--chakra-colors-blue-200);
    border-bottom-left-radius: var(--chakra-radii-md);
    border-bottom-right-radius: var(--chakra-radii-md);
  }
}